<template>
  <b-card
    no-body
    no-border
    no-shadow
  >
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <img
        width="42"
        class="mr-1"
        :src="require(`@/assets/images/logos/${service.name}.webp`)"
      >
      <b-card-title>{{ service.name }}</b-card-title>
    </b-card-header>
    <!--/ title and dropdown -->
    <b-card-body>
      <b-row>
        <!-- chart -->
        <b-col
          sm="12"
          class="d-flex justify-content-center"
        >

          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="200"
            class="pt-2"
            :options="goalOverviewRadialBar"
            :series="series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <b-row class="avg-sessions pt-0">
        <b-col
          cols="6"
          class="mb-2"
          v-if="dlp > -1"
        >
          <small class="mb-50">
            DLP: {{ dlp }}%
          </small>
          <b-progress
            :value="dlp"
            max="100"
            height="6px"
            :variant="dlp > 75 ? 'success' : dlp > 50 ? 'warning' : 'danger'"
          />
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
          v-if="iam > -1"
        >
          <small class="mb-50">
            IAM: {{ iam }}%
          </small>
          <b-progress
            :value="iam"
            max="100"
            height="6px"
            :variant="iam > 75 ? 'success' : iam > 50 ? 'warning' : 'danger'"
          />
        </b-col>
        <b-col cols="6" v-if="malops > -1">
          <small class="mb-50">
            MALOPS: {{ malops }}%
          </small>
          <b-progress
            :value="malops"
            max="100"
            height="6px"
            :variant="malops > 75 ? 'success' : malops > 50 ? 'warning' : 'danger'"
          />
        </b-col>
        <b-col cols="6" v-if="uba > -1">
          <small class="mb-50">
            UBA: {{ uba }}%
          </small>
          <b-progress
            :value="uba"
            max="100"
            height="6px"
            :variant="uba > 75 ? 'success' : uba > 50 ? 'warning' : 'danger'"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BProgress,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $strokeColor = '#ebe9f1'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BProgress,
    BCol,
  },
  props: {
    service: {
      type: Object,
    },
    totalScore: {
      type: Number,
    },
    color: {
      type: String,
    },
    uba: {
      type: Number,
    },
    dlp: {
      type: Number,
    },
    malops: {
      type: Number,
    },
    iam: {
      type: Number,
    },
  },
  data() {
    return {
      trackerData: {},
      series: [this.totalScore],
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [this.color],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: this.color,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [this.color],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      supportTrackerRadialBar: {
        series: [0],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Completed Tickets'],
        },
      },
    }
  },
  created() {
    // this.$http.get('/card/card-analytics/support-tracker').then(res => { this.trackerData = res.data })
  },
}
</script>
