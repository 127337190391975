<template>
  <b-card no-body class="px-0" v-if="chartData.length > 0">
    <b-card-header>
      <div class="d-flex align-items-center">
        <b-avatar
          class="my-1"
          variant="light-primary"
          size="45"
        >
          <feather-icon
            size="21"
            icon="CloudLightningIcon"
          />
        </b-avatar>
        <div class="ml-1">
          <h2 class="mb-0 font-weight-bolder">
            {{ chartData.length }}
          </h2>
          <span>Open Action Items</span>
        </div>
      </div>
      <b-badge
        variant="primary"
      >
        <router-link
          to="/actions"
        >
          watch all
        </router-link>
      </b-badge>
    </b-card-header>

    <b-card-body class="px-0">
      <b-table
        :items="chartData.slice(0,5)"
        :fields="['title', 'priority']">
        <template #cell(priority)="data">
          <b-badge
            :variant="`light-${getColor(data.item.priority)}`"
            class="mr-1"
          >
            {{ data.item.priority }}
          </b-badge>
          </template>
          <template #cell(risk)="data">
            {{ data.item.error }}
          </template>
          <template #cell(applications)="data">
            <div v-if="data.item.applications">
              <img
                v-for="application in Object.keys(data.item.services).slice(0, 4)"
                :key="application"
                width="32"
                style="margin-right: 8px"
                :src="require(`@/assets/images/logos/${application}.webp`)"
              >
              <b-avatar
                v-if="Object.keys(data.item.applications).length > 4"
                size="28"
                :text="`${Object.keys(data.item.applications).length - 4}+`"
              />
            </div>
          </template>
      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BTable, BBadge, BAvatar,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BTable,
    BAvatar,
  },
  data() {
    return {
      customersPie: {
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['User Account Compromise', 'Application Compromise', 'Information Disclosure', 'Source Code Disclosure'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger, $themeColors.info],
        },
      },
    }
  },
  props: {
    chartData: {
      type: Array,
    },
  },
}
</script>
