<template>
  <section id="dashboard-analytics">
    <checker />
    <b-row class="match-height" v-if="Object.keys(stats).length > 0">
        <b-col
        lg="4"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="ordersRecevied"
          icon="UsersIcon"
          :statistic="Object.keys(stats.identities).length"
          statistic-title="Risky Identities"
          :chart-data="ordersRecevied.series"
          link="identities"
        />
      </b-col>
      <b-col
        lg="4"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="ordersRecevied"
          icon="DatabaseIcon"
          :statistic="Object.keys(stats.resources).length"
          statistic-title="Risky Resources"
          :chart-data="ordersRecevied.series"
          link="resources"
        />
      </b-col>
      <b-col
        lg="4"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="ordersRecevied"
          icon="PackageIcon"
          :statistic="Object.keys(stats.configurations).length"
          statistic-title="Risky Configurations"
          :chart-data="ordersRecevied.series"
          link="configurations"
        />
      </b-col>
      <b-col lg="4">
        <card-top-scenarios :chartData="stats.actions" />
      </b-col>
      <b-col lg="4">
        <card-analytic-root :chartData="rootCause" />
      </b-col>
      <b-col lg="4">
        <card-analytic-customers :chartData="potentialImpact" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        v-for="app in applications.slice(0, applicationsShow)"
        :key="app.name"
        lg="3"
      >
        <analytics-application-score
          :service="app"
          :total-score="calcScore(app.total)"
          :iam="calcScore(app.IAM)"
          :uba="calcScore(app.UBA)"
          :malops="calcScore(app.MALOPS)"
          :dlp="calcScore(app.DLP)"
          :color="scoreToColor(calcScore(app.total))"
        />
      </b-col>
    </b-row>
    <b-row v-if="applications.length > applicationsShow">
      <b-col
        class="text-center"
        lg="12"
      >
        <b-button
          variant="outline-primary"
          @click="applicationsShow+=6"
        >
          Show More
        </b-button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import store from '@/store'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import AnalyticsApplicationScore from '../dashboard/analytics/AnalyticsApplicationScore.vue'
import CardAnalyticCustomers from '../card/card-analytic/CardAnalyticCustomers.vue'
import CardAnalyticRoot from '../card/card-analytic/CardAnalyticRoot.vue'
import CardTopScenarios from '../card/card-analytic/CardTopScenarios.vue'
import useScenariosList from './api/useScenariosList'
import scenarioStoreModule from './api/scenarioStoreModule'
import Checker from './Checker.vue'

export default {
  components: {
    BRow,
    BCol,
    CardTopScenarios,
    StatisticCardWithAreaChart,
    AnalyticsApplicationScore,
    CardAnalyticCustomers,
    CardAnalyticRoot,
    Checker,
    BButton,
  },
  data() {
    return {
      stats: {},
      data: {},
      standards: {},
      applications: [],
      applicationsShow: 6,
      rootCause: {},
      potentialImpact: {},
      congratulations: {
        name: 'John',
        saleToday: '57.6',
      },
      subscribersGained: {
        series: [
          {
            name: 'Subscribers',
            data: [28, 40, 36, 52, 38, 60, 55],
          },
        ],
        analyticsData: {
          subscribers: 35,
        },
      },
      ordersRecevied: {
        series: [
          {
            name: 'Orders',
            data: [10, 15, 8, 15, 7, 12, 8],
          },
        ],
        analyticsData: {
          orders: 38400,
        },
      },
    }
  },
  mounted() {
    this.stats = localStorage.getItem('stats')
    if (this.stats) {
      this.stats = JSON.parse(this.stats)
      this.initGraphs(this.stats)
    }
    this.fetchStats(null, this.initGraphs)
  },
  methods: {
    initGraphs(stats) {
      this.stats = stats
      this.applications = Object.values(stats.applications)
      this.rootCause = {
        lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
        listData: [
          {
            icon: 'CircleIcon',
            iconColor: 'text-primary',
            text: 'Identity Access Management',
            result: stats.rootCause.IAM,
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-warning',
            text: 'Data Loss Protection',
            result: stats.rootCause.DLP,
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-danger',
            text: 'User Behaviour Analysis',
            result: stats.rootCause.UBA,
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-info',
            text: 'Malicious Operations',
            result: stats.rootCause.MALOPS,
          },
        ],
        series: [stats.rootCause.IAM, stats.rootCause.DLP, stats.rootCause.UBA, stats.rootCause.MALOPS],
      }
      this.potentialImpact = {
        lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
        listData: [
          {
            icon: 'CircleIcon',
            iconColor: 'text-primary',
            text: 'Information Disclosure',
            result: stats.potentialImpact['Information Disclosure'],
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-warning',
            text: 'User Account Compromise',
            result: stats.potentialImpact['User Account Compromise'],
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-danger',
            text: 'Application Compromise',
            result: stats.potentialImpact['Application Compromise'],
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-info',
            text: 'Source Code Disclosure',
            result: stats.potentialImpact['Source Code Disclosure'],
          },
        ],
        series: [stats.potentialImpact['Information Disclosure'], stats.potentialImpact['User Account Compromise'], stats.potentialImpact['Application Compromise'], stats.potentialImpact['Source Code Disclosure']],
      }
      this.standards = {
        series: [
          {
            name: 'Fail',
            data: [stats.standards.hipaa[0], stats.standards.cis[0], stats.standards.pci[0]],
          },
          {
            name: 'Passed',
            data: [stats.standards.hipaa[1], stats.standards.cis[1], stats.standards.pci[1]],
          },
        ],
        chartOptions: {
          chart: {
            stacked: false,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: ['hipaa', 'cis', 'pci'],
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.86rem',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.danger, $themeColors.success],
          plotOptions: {
            bar: {
              columnWidth: '50%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.86rem',
              },
            },
          },
        },
      }
    },
    kFormatter,
    showAppsScore(response) {
      this.applications = response
    },
    scoreToColor(score) {
      if (score > 75) return $themeColors.success
      return score > 50 ? $themeColors.warning : $themeColors.danger
    },
    calcScore(section) {
      if (section[1] === 0) {
        return -1
      }

      return Math.floor((section[0] / section[1]) * 100)
    },
  },
  setup() {
    const SCENARIO_APP_STORE_MODULE_NAME = 'app-scenario'

    // Register module
    if (!store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.registerModule(SCENARIO_APP_STORE_MODULE_NAME, scenarioStoreModule)

    const {
      fetchStats,
      fetchScenarios,
    } = useScenariosList()

    return {
      fetchStats,
      fetchScenarios,
    }
  },
}
</script>
